import React, { useState } from 'react';
import { Routes, Route, NavLink } from "react-router-dom";
import i18n from './i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faArchive, faKey } from '@fortawesome/pro-light-svg-icons'
import classNames from 'classnames';

import { auth } from './services/FirebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';


import { Layout } from '@sumit26/sumit-ai-components';
import { MenuItem } from '@sumit26/sumit-ai-components/dist/types/interfaces';
import Breadcrumbs from './components/common//Breadcrumbs/Breadcrumbs';
import { Lobby } from './pages/Lobby/Lobby';
import { LiveEditor } from './components/LiveEditor/LiveEditor';
import { ManualTranscriptor } from './pages/ManualTranscriptor/ManualTranscriptor';
import { Archive } from './pages/Archive/Archive';
import { Login } from './pages/Login/Login';

import { Loader } from './components/common/Loader/Loader';

import Logo from "./assets/sumit-logo-white.png";
import Asher from "./assets/asher.png"

import './App.scss';

function App() {
  const [user, loading, error] = useAuthState(auth);
  const [langDirection] = useState(i18n.language.startsWith("he") ? "rtl" : "ltr");
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const menuConfig: MenuItem[] = [
    {
      page: "home",
      label: i18n.t("menu_home"),
      icon: <FontAwesomeIcon icon={faHome} />,
      render: (children: React.ReactNode) => <NavLink to="/" key="home">{children}</NavLink>,
      hidden: !user
    },
    {
      page: "archive",
      label: i18n.t("menu_archive"),
      icon: <FontAwesomeIcon icon={faArchive} />,
      render: (children: React.ReactNode) => <NavLink to="/archive" key="archive">{children}</NavLink>,
      hidden: !user
    },
    {
      page: "login",
      label: i18n.t("menu_login"),
      icon: <FontAwesomeIcon icon={faKey} />,
      render: (children: React.ReactNode) => <NavLink to="/login" key="login">{children}</NavLink>
    },
  ]

  return (
    <div className={classNames("App", langDirection)} key={i18n.language}>
      <Layout
        logoUrl={Logo}
        menuConfig={menuConfig.filter(i => !i.hidden)}
        isOpen={isMenuOpen}
        handleOnPageClick={() => console.log('a')}
        profile={
          {
            metaData: { id: "accessibility2", name: "כנס נגישות", avatarUrl: Asher },
            showDetails: true,
          }}
        logoCloseUrl={""}
        breadcrumbs={<Breadcrumbs />}
        onSearchChange={console.log}
        searchTerm={""}
        toggleIsOpen={() => setIsMenuOpen(!isMenuOpen)}
      >{
          loading ?
            <Loader /> : (
              <Routes>
                <Route path="/" element={<Lobby />} />
                <Route path="archive" element={<Archive />} />
                <Route path="live" element={<LiveEditor />}>
                  <Route path=":jobId" element={<LiveEditor />} />
                </Route>
                <Route path="man" element={<ManualTranscriptor />} />
                <Route path="login" element={<Login />} />
                <Route path="*" element={<div>NoPage </div>} />
              </Routes>
            )}
      </Layout>
    </div>
  );
}

export default App;
