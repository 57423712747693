import React, { FC, useEffect, useState } from 'react'
import _ from 'lodash';
import MUITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Props {
  data: any[],
  config?: {
    columns?: string[]
  }
}

export const Table: FC<Props> = ({
  data,
  config
}) => {

  const [tableColumns, setTableColumns] = useState<string[]>([])

  useEffect(() => {
    if (_.isEmpty(data)) return;

    const autoTableColumns = _.keys(data[0]);
    const _tableColumns = config?.columns || autoTableColumns

    setTableColumns(_tableColumns);
  }, [config])

  return (
    <div className="Table">
      <MUITable sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {_.map(tableColumns, column => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </div>
  )
}

export default Table
