import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { auth } from '../../services/FirebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';

import { LiveJob } from '../../types/Live';

import { getUserJobs } from '../../services/JobService';
import RealtimeService from '../../services/RealtimeService';

import Table from '../../components/common/Table/Table';
import Button from "../../components/common/Button/Button";

import "./Archive.scss";

export const Archive = () => {
  const [user, loading, error] = useAuthState(auth);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [jobs, setJobs] = useState<LiveJob[]>([]);

  useEffect(() => {
    getUserJobs("accessibility2", { status: "ended" }).then(_jobs => setJobs(_jobs));
  }, [])

  const goToSession = (jobId: string) => {
    navigate(`/live/${jobId}`)
  }

  return !user ? <Navigate to="/login" /> : (
    <div className="ArchivePage">
      <div className="archivedJobs">
        <Table data={jobs} config={{
          columns: ["name", "lang", "speaker", "creationTime"]
        }} />
      </div>
    </div>
  )
}
