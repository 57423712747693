import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { LiveTranscript } from "../../types/Live";
import { PendingThreeDots } from "./PendingThreeDots";

import Logo from "../../assets/sumit-logo-white.png";

import "./Subtitler.scss";

interface Props {
  transcript: LiveTranscript;
  isTranscribing: boolean;
  fontSize: number;
  direction: "rtl" | "ltr";
  maxLines?: number;
  maxWordsInLine?: number;
}

export const Subtitler: React.FC<Props> = ({
  transcript,
  isTranscribing,
  fontSize = 16,
  direction,
  maxLines = 2,
  maxWordsInLine = 8,
}) => {

  const [mainSubtitle, setMainSubtitle] = useState<string[]>([]);
  const [secondarySubtitle, setSecondarySubtitle] = useState<string[]>([]);

  const containerRef = useRef(null);
  const mainSubtitleRef = useRef(null);
  const secondarySubtitleRef = useRef(null);

  useEffect(() => {
    generateTwoLinesSubtitles(transcript.words);
  }, [transcript.words])

  const generateTwoLinesSubtitles = (words: string[]) => {
    const lastWord = _.last(words);

    if (!lastWord || !containerRef.current) return;

    const updatedMainSubtitleWidth = calcTextWidth([...mainSubtitle, lastWord].join(" ")) || 0;
    
    const containerStyle = window.getComputedStyle(containerRef.current);
    const containerPadding = Number(containerStyle.padding.replace("px", ""));
    
    // @ts-ignore
    const isLineBreak = updatedMainSubtitleWidth > (containerRef.current?.offsetWidth - 80 - (containerPadding*2));
    if (isLineBreak) {
      // @ts-ignore
      setSecondarySubtitle([...mainSubtitle]);
      setMainSubtitle([lastWord]);
      return;
    }

    setMainSubtitle([...mainSubtitle, lastWord]);
  };

  const calcTextWidth = (text: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    
    if (!mainSubtitleRef.current || !context) return

    context.font = getComputedStyle(mainSubtitleRef.current).font;
  
    return context.measureText(text).width;
  }
  
  const resetSubtitles = () => {
    setMainSubtitle([])
    setSecondarySubtitle([])
  }

  return (
    <div className={classNames("Subtitler", direction)} onDoubleClick={resetSubtitles} ref={containerRef}>
      <div
        className="secondarySubtitle subtitle"
        style={{ fontSize }}
        ref={secondarySubtitleRef}
      >
        {secondarySubtitle.join(" ")}
      </div>
      <div
        className="mainSubtitle subtitle"
        style={{ fontSize }}
      >
        <span ref={mainSubtitleRef}>{mainSubtitle.join(" ")}</span>
        <span>
          <PendingThreeDots />
        </span>
      </div>
      <div className="logoLine">
        <span className="poweredBy">powered by </span>
        <img className="logo" src={Logo} alt="" />
      </div>
    </div>
  );
};
