import React, { FC, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

import i18n from "../../i18n";
import { getLanguageDirection } from "../../utils/locales";

import Button from "../common/Button/Button";
import Input from "../common/Input/Input";
import Dropdown from "../common/Dropdown/Dropdown";

import "./StartNewLiveModal.scss";

interface Props {
  showModal: boolean;
  approve?: {
    text: string;
    action: ({
      name,
      speaker,
      lang
    }: {
      name: string;
      speaker: string;
      lang: string
    }) => void | Promise<void>;
  };
  cancel?: {
    text: string;
    action: () => void | Promise<void>;
  };
  className?: string;
  isApproveDisabled?: boolean;
}

const StartNewLiveModal: FC<Props> = ({
  showModal,
  approve,
  cancel,
  className,
  isApproveDisabled,
}): JSX.Element => {
  const { t } = useTranslation();

  const [newJob, setNewJob] = useState({
    name: "",
    speaker: "",
    lang: "he-IL"
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeNewJob = (field: string, newValue: string) => {
    const _newJob = { ...newJob, [field]: newValue };
    setNewJob(_newJob);
  }

  return (
    <Dialog
      className={getLanguageDirection(i18n.language)}
      classes={{ paper: classNames("StartNewLiveModal", className) }}
      open={showModal}
    >
      <DialogTitle>
        <div className="modalTitle">{t("lobby_start_new_live")}</div>
      </DialogTitle>
      <DialogContent style={{ overflow: "visible" }}>
        <div className="modalBody">
          <Input
            value={newJob.name}
            onChange={(e) => handleChangeNewJob("name", e.target.value)}
            placeholder={t("lobby_new_live_name")}
          />
          <Input
            value={newJob.speaker}
            onChange={(e) => handleChangeNewJob("speaker", e.target.value)}
            placeholder={t("lobby_new_live_speaker")}
          />
          <Dropdown
          direction={i18n.language.startsWith("he") ? "rtl" : "ltr"}
            options={[
              { label: "עברית", value: "he-IL" },
              { label: "English", value: "en-US" },
              { label: "Español", value: "es-PA" },
            ]} 
            searchOption={false}
            value={"he-IL"}
            placeholder={t("lobby_new_live_lang")}
            onChange={(val) => handleChangeNewJob("lang", val as string)}/>
          {/* <Input 
            value={newJob.lang}
            onChange={(e) => handleChangeNewJob(e, "lang")}
            placeholder={t("lobby_new_live_lang")}
          /> */}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="buttons">
          {cancel && (
            <Button
              className="button cancel"
              variant="outlined"
              onClick={cancel.action}
              disabled={isLoading}
              key={1}
            >
              {cancel.text}
            </Button>
          )}
          {approve && (
            <Button
              className="button approve"
              disabled={isApproveDisabled}
              variant="contained"
              onClick={() => {
                setIsLoading(true);
                approve.action(newJob);
              }}
              loading={isLoading}
              key={2}
            >
              {approve.text}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default StartNewLiveModal;
