import config from "../config"
import axios from "axios"
import { LiveJob } from "../types/Live";

export const getUserJobs = async (userId: string, options?: { status?: string }) => {
  const getUserJobsEndpoint = config.cloudFunctions.baseUrl + config.cloudFunctions.jobs.getUserJobs;
  const jobs = await axios.get(getUserJobsEndpoint, {
    params: {
      userId,
      ...options
    } 
  })

  return jobs.data;
}

export const getJob = async (jobId: string) => {
  const getJobEndpoint = config.cloudFunctions.baseUrl + config.cloudFunctions.jobs.getJob;
  const job = await axios.get(getJobEndpoint, {
    params: {
      jobId
    }
  })

  return job.data;
}

export const saveJob = async (userId: string, job: LiveJob) => {
  const saveJobEndpoint = config.cloudFunctions.baseUrl + config.cloudFunctions.jobs.saveJob;
  const jobs = await axios.post(saveJobEndpoint, {
    data: job
  })

  return jobs.data;
}