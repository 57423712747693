import React, { FocusEvent, useEffect, useRef, useState } from 'react'
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { auth } from '../../services/FirebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';

import { format } from 'date-fns';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont, faGear, faClosedCaptioning, faClosedCaptioningSlash } from '@fortawesome/pro-light-svg-icons'
import { faArrowDown, faCircleMicrophone, faCirclePause, faHighlighter } from '@fortawesome/pro-solid-svg-icons'

import { getLanguageDirection } from '../../utils/locales';

import { LiveEditorRange, LiveJob, LiveTranscript } from '../../types/Live';

import Logger from "../../services/Logger";
import RealtimeService from '../../services/RealtimeService';
import { getJob } from '../../services/JobService';

import Dropdown from '../common/Dropdown/Dropdown';
import { Subtitler } from './Subtitler';
import { PendingThreeDots } from './PendingThreeDots';
import { Loader } from '../common/Loader/Loader';

import RobotIdleHappy from "../../assets/robot-idle-happy.png"
import "./LiveEditor.scss"

const logger = Logger("LiveEditor");

export const LiveEditor = () => {
  const [user, loading, error] = useAuthState(auth);
  const { t } = useTranslation()
  const { jobId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [job, setJob] = useState<LiveJob>();

  const [ranges, setRanges] = useState<LiveEditorRange[]>([]);
  const [liveTranscript, setLiveTranscript] = useState<LiveTranscript>({
    words: [],
    start: new Date(),
    pendingWord: "",
    unstableWord: ""
  });
  const liveWords = useRef<string[]>([]);

  const [selectionPosition, setSelectionPosition] = useState<[number, number] | null>(null)

  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubtitlesMode, setIsSubtitlesMode] = useState(false);
  const [editorDirection, setEditorDirection] = useState<"rtl" | "ltr">("rtl")
  const [fontSize, setFontSize] = useState(24);
  const fontSizeOptions = [17, 21, 24, 29, 35, 41, 80, 100, 120];
  const [debugMode] = useState(searchParams.get("d"));
  const startActions = [
    {
      key: "toggleRecord",
      icon: <FontAwesomeIcon className={classNames("recordButton", {recording: isRecording})} icon={faCircleMicrophone} />,
      className: isRecording ? "recording" : "",
      onClick: () => handleRecordToggle()
    },
    {
      key: "endSession",
      label: t("live_editor_end_session"),
      className: isRecording ? "recording" : "",
      onClick: () => handleStopRealtimeSession()
    },
    {
      key: "debug",
      label: t("debug"),
      onClick: () => toggleDebug(),
      hide: !debugMode
    },
  ]

  const endActions = [
    {
      key: "subtitlesMode",
      icon: <FontAwesomeIcon icon={faClosedCaptioning} />,
      className: "small",
      onClick: () => setIsSubtitlesMode((prevState) => !prevState)
    },
    {
      key: "settings",
      icon: <FontAwesomeIcon icon={faGear} />,
      className: "small",
      onClick: console.log
    },
    {
      key: "fontSize",
      icon: <FontAwesomeIcon icon={faFont} />,
      render: () => <Dropdown options={fontSizeOptions.map(o => ({ value: o, label: o.toString() }))} onChange={handleFontSizeChange} placeholder={fontSize.toString()} searchOption={false} />,
      className: "small",
      onChange: console.log
    }
  ]


  const transcriptRef = useRef<HTMLDivElement>(null);
  const transcriptScrollerRef = useRef<HTMLDivElement>(null);
  const [autoScrollAtteched, setAutoScrollAtteched] = useState(true);

  useEffect(() => {
    if (job || !jobId) return;
    getJob(jobId).then(j => setJob(j))
    return () => {
      RealtimeService.stop()
      RealtimeService.resetRealtimeSession()
    }
  }, [])

  useEffect(() => {
    if (!job) return;

    const jobDirection = getLanguageDirection(job.lang);
    setEditorDirection(jobDirection);
    loadRangesFromLocalStorage();

    RealtimeService.initRealtimeSession(job);
    RealtimeService.setOnTranscript = handleReceiveTranscript;
  }, [job])

  useEffect(() => {
    if (!transcriptScrollerRef?.current || !autoScrollAtteched) return;
    transcriptScrollerRef.current.scrollIntoView({ behavior: "smooth" })
  }, [liveTranscript])

  useEffect(() => {
    RealtimeService.setOnTranscript = handleReceiveTranscript;
  }, [ranges, liveTranscript])

  const handleReceiveTranscript = async (rawTranscript: string) => {
    const re = new RegExp("^\\[.+\\]");
    const actionRequest = re.test(rawTranscript); // Message from server - [NEW_STREAM] | [FLUSH] | ...
    const [verifiedWord, pendingWord, unstableWord] = rawTranscript.split("|");

    if (actionRequest) {
      startNewRange()
      return;
    }

    liveWords.current = [...liveWords.current, verifiedWord];

    setLiveTranscript(prevLiveTranscript => {
      const updatedLiveTranscript = {
        words: liveWords.current,
        start: prevLiveTranscript.start,
        unstableWord: unstableWord || prevLiveTranscript.unstableWord,
        pendingWord: pendingWord || prevLiveTranscript.pendingWord,
      }

      saveRangesToLocalStorage([...ranges, _.pick(liveTranscript, ["start", "words"])])

      return updatedLiveTranscript
    })
    console.log([verifiedWord, pendingWord, unstableWord]);
  };

  const startNewRange = () => {
    if (_.isEmpty(liveWords.current)) {
      setLiveTranscript(prevLiveTranscript => {
        return { ...prevLiveTranscript, start: new Date() }
      })
      return;
    }

    const currentLiveWords = liveWords.current;
    setRanges(prevRanges => {
      const updatedRanges = [...prevRanges];
      updatedRanges.push({
        start: liveTranscript.start,
        words: currentLiveWords,
      });
      return updatedRanges;
    });

    resetLiveTranscript()
  }

  const resetLiveTranscript = () => {
    liveWords.current = [];
    setLiveTranscript({
      words: [],
      start: new Date(),
      pendingWord: "",
      unstableWord: ""
    })
  }

  const saveRangesToLocalStorage = (rangesToSave: LiveEditorRange[]) => {
    if (!job) return;
    const stringifiedRanges = JSON.stringify(rangesToSave)
    localStorage.setItem(job.jobId, stringifiedRanges);
  }

  const loadRangesFromLocalStorage = () => {
    if (!job) return;
    const savedRanges = localStorage.getItem(job.jobId);
    const parsedRanges = savedRanges ? JSON.parse(savedRanges) : [];
    setRanges(parsedRanges);
  }

  const handleRecordToggle = () => {
    // if (!loggedInUser || (!job)) return;
    if (isRecording) {
      RealtimeService.pause()
    } else {
      RealtimeService.record()
    }
    setIsRecording(!isRecording);
  }

  const handleStopRealtimeSession = async () => {
    if (!job) return;
    try {
      setIsLoading(true);
      await RealtimeService.endLiveSession(job.jobId)
      setIsLoading(false);
      navigate("/");
    } catch (err) {
      logger.error(err)
    }
  }

  const toggleDebug = () => {
    handleReceiveTranscript(`[NEW_STREAM]`)
    // const interval = setInterval(() => {
    //   // const dummyWords = ["[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]","[FLUSH]", "איפסום", "דולור", "סיט", "אמט,", "קונסקטטור", "אדיפיסינג", "אלית", "קולורס", "מונפרד", "אדנדום", "סילקוף,", "מרגשי", "ומרגשח.", "עמחליף", "לפרומי", "בלוף־קינץ", "תתיח", "לרעח.", "לת", "צשחמי", "צש", "בליא,", "מנסוטו", "צמלח", "לביקו", "ננבי,", "צמוקו", "בלוקריה", "ש”יצמה", "ברורק“.", "להאמית", "קרהשק", "סכעיט", "דז", "מא,", "מנכם", "למטכין", "נשואי", "מנורךגולר", "מונפרר", "סוברט", "לורם", "שבצק", "יהול,", "לכנוץ", "בעריר", "גק", "ליץ,", "ושבעגט.", "ושבעגט", "לבם", "סולגק.", "בראיט", "ולחת", "צורק", "מונחף,", "בגורמי", "מג׳מש.", "תרבנך", "וסתעד", "לכנו", "סתשם", "השמה", "–", "לתכי", "מורגם", "בורק?", "לתיג", "ישבעס."]
      const dummyWords = ["אדיפיסינג", "אלית", "קולורס", "מונפרד", "אדנדום", "סילקוף,", "מרגשי", "ומרגשח.", "עמחליף", "לפרומי", "בלוף־קינץ", "תתיח", "לרעח.", "לת", "צשחמי", "צש", "בליא,", "מנסוטו", "צמלח", "לביקו", "ננבי,", "צמוקו", "בלוקריה", "ש”יצמה", "ברורק“.", "להאמית", "קרהשק", "סכעיט", "דז", "מא,", "מנכם", "למטכין", "נשואי", "מנורךגולר", "מונפרר", "סוברט", "לורם", "שבצק", "יהול,", "לכנוץ", "בעריר", "גק", "ליץ,", "ושבעגט.", "ושבעגט", "לבם", "סולגק.", "בראיט", "ולחת", "צורק", "מונחף,", "בגורמי", "מג׳מש.", "תרבנך", "וסתעד", "לכנו", "סתשם", "השמה", "–", "לתכי", "מורגם", "בורק?", "לתיג", "ישבעס."]
      handleReceiveTranscript(`${_.sample(dummyWords)}|${_.sample(dummyWords)}|${_.sample(dummyWords)}`)
    // }, 800)
  }
 
  const handleFontSizeChange = (size: any) => {
    setFontSize(size)
  }

  const handleTranscriptOnWheel = (e: React.UIEvent<HTMLElement>) => {
    if (!transcriptRef.current) return;
    const { scrollHeight, scrollTop, clientHeight } = transcriptRef.current;
    const scrolledToBottom = scrollHeight - 50 <= scrollTop + clientHeight;
    setAutoScrollAtteched(scrolledToBottom);
  }

  const handleTranscriptOnMouseDown = (e: React.UIEvent<HTMLElement>) => {
    setAutoScrollAtteched(false);
    handleReceiveTranscript(`[NEW_RANGE]`)
  }

  const handleTranscriptOnMouseUp = (e: React.UIEvent<HTMLDivElement>) => {
    const selection = document.getSelection();
    if (!selection) return;

    const selectedWords = selection.getRangeAt(0);
    const selectedPosition = selectedWords.getBoundingClientRect();


    // console.log(selectedPosition);

    setSelectionPosition([selectedPosition.top, selectedPosition.left])

    const { anchorOffset, focusOffset } = selection;
  }

  const handleOnBlur = (e: FocusEvent<HTMLDivElement>) => {
    const dataRangeIndex = e.currentTarget.getAttribute("data-range-index");
    if (!dataRangeIndex) return;
    const rangeIndex = Number(dataRangeIndex);

    const newText = e.target.innerText;
    if (_.isEmpty(newText)) {
      console.log('removed');
    }

    const formattedText = newText.split(/\s+/);

    setRanges(prevRanges => {
      const updatedRanges = prevRanges;
      updatedRanges[rangeIndex].words = formattedText;

      saveRangesToLocalStorage([...updatedRanges, _.pick(liveTranscript, ["start", "words"])])

      return updatedRanges;
    })
  }

  const handleScrollToBottom = () => {
    if (!transcriptScrollerRef?.current) return;
    transcriptScrollerRef.current.scrollIntoView({ behavior: "smooth" })
    setAutoScrollAtteched(true);
  }

  return !user ? <Navigate to="/login" /> : 
  !job ? (
    <Loader />
  ) : (
    <div className={classNames("LiveEditorComponent", {subtitlesMode: isSubtitlesMode})}>
      <div className="liveEditorHeader">
        <div className="liveEditorStartActions">
          {_.map(startActions, action => !action.hide && (
            <div
              className={classNames(action.key, "action", action.className)}
              onClick={action.onClick}
              key={action.key}
            >
              {action.icon || action.label}
            </div>
          ))}
        </div>
        <div className="jobInfo">
          <div className="jobName">
            {job.name}
          </div>
          <div className="creationTime">
            {format(new Date(job.creationTime), "HH:mm dd/MM/yyyy")}
          </div>
        </div>
        <div className="liveEditorEndActions">
          {_.map(endActions, action => (
            <div
              className={classNames(action.key, "action", action.className)}
              onClick={action.onClick}
              key={action.key}
            >
              {action.icon}
              {action.render && action.render()}
            </div>
          ))}
        </div>
      </div>
      {!_.isEmpty(ranges) || !_.isEmpty(liveTranscript.words) ? 
        isSubtitlesMode ? (
          <Subtitler 
            transcript={liveTranscript} 
            fontSize={fontSize} 
            direction={editorDirection}
            isTranscribing={isRecording && !_.isEmpty(liveTranscript.pendingWord)}
          />
        ) : (
        <div
          className={classNames("transcript", editorDirection)}
          onWheel={handleTranscriptOnWheel}
          ref={transcriptRef}>
          {ranges &&
            _.map(ranges, (range, i: number) => (
              <div className="transcriptRange" key={i}>
                <div className="rangeTimes" style={{ fontSize }}>{format(new Date(range.start), "HH:mm:ss")}</div>
                <div
                  className="rangeText"
                  style={{ fontSize, lineHeight: `${fontSize + 22}px` }}
                  onMouseUp={handleTranscriptOnMouseUp}
                  onMouseDown={() => setSelectionPosition(null)}
                  onMouseMove={handleTranscriptOnMouseUp}
                  onBlur={handleOnBlur}
                  data-range-index={i}
                  contentEditable
                  suppressContentEditableWarning>
                  {range.words.join(" ")}
                </div>
              </div>
            ))
          }
          <div className="transcriptRange lastRange" style={{
            paddingBottom: transcriptRef.current?.clientHeight ? transcriptRef.current?.clientHeight * 0.3 : 0
          }}>
            <div className="rangeTimes" style={{ fontSize }}>
              {format(new Date(liveTranscript.start), "HH:mm:ss")}
            </div>
            <div
              className="rangeText"
              style={{ fontSize, lineHeight: `${fontSize + 16}px` }}
              onMouseDown={handleTranscriptOnMouseDown}
            >
              {liveTranscript.words.join(" ")}
              {isRecording && liveTranscript.pendingWord && (
                <PendingThreeDots />
              )}
            </div>
          </div>
          <div className="transcriptScroller" ref={transcriptScrollerRef}></div>
          <div className={classNames("scrollToBottomButton", { hidden: autoScrollAtteched })} onClick={handleScrollToBottom}>
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      ) : (
        <div className="robotMessage noTranscript">
          <div className="upperMessage">{t("no_transcript_upper")}</div>
          <div className="robotImage"><img src={RobotIdleHappy} alt="" /></div>
          <div className="bottomMessage">{t("no_transcript_bottom")}</div>
        </div>
      )
      }
      {
        isLoading && (
          <Loader />
        )
      }
    </div>
  )
}