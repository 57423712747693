import _ from "lodash";
import config from "../config";
import axios, { AxiosResponse } from "axios";
import { io, Socket } from "socket.io-client";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import { format } from "date-fns";
import { generateId } from "../utils/generators";

import Logger from "../services/Logger";

// import FirebaseService from "./FirebaseService";
// import { LoggedInUser } from "../models/user";
import { LiveJob } from "../types/Live";
// import { JobRange } from "../models/range";
// import { Annotations } from "../models/annotations";
// import EditorService from "./EditorService";
// import { Word } from "../models";


interface ClientToServerEvents {
  data: (audioChunk: Int16Array) => void;
  manTranscript: (transcript: string) => void;
}

interface ServerToClientEvents {
  connection: (socket: any) => void
  transcript: (trans: string) => void;
  manTranscript: (trans: string) => void;
}

interface Transcript {
  ranges: {
    start: Date;
    words: string[];
  }[];
  pending: string;
  unstable: string;
}

const logger = Logger("RealtimeService")

class Realtime {
  private static instance: Realtime;
  private host: Socket<ServerToClientEvents, ClientToServerEvents>;
  private client: Socket<ServerToClientEvents, ClientToServerEvents>;
  private manualClient: Socket<ServerToClientEvents, ClientToServerEvents>;
  public RecordRTC: RecordRTC;
  private MediaStream: MediaStream
  public transcript: Transcript;

  private sampleRate = 22050;

  public handleOnTranscript: (rawTranscript: string) => void;
  public set setOnTranscript(handler: (rawTranscript: string) => void) {
    this.handleOnTranscript = handler;
  }

  public initRealtimeSession = async (job: LiveJob) => {
    if (!job) return;

    this.transcript = {
      ranges: [],
      pending: "",
      unstable: "",
    };

    this.host = io(`https://${job.audioStreamUrl}`, { transports: ['websocket'] });
    this.client = io(`https://${job.transcriptStreamUrl}`, { transports: ['websocket'] });

    this.client.removeAllListeners();

    this.client.on("transcript", (trans: any) => {
      console.log(trans);
      this.handleOnTranscript(trans);
    });

    this.client.on("manTranscript", (trans: any) => {
      console.log("manual", trans);
      this.handleOnTranscript(trans);
    });
  };
  
  public initManualTranscriptor = async ({
    jobId,
    onConnect
  }:{
    jobId: string, 
    onConnect: () => void
  }) => {
    this.manualClient = io(`https://${jobId}.sumit-labs.com`);

    this.manualClient.removeAllListeners();

    this.manualClient.on("connect", onConnect)
  };

  public closeManualTranscriptor = async (jobId: string, event: string) => {
    this.manualClient.close()
  };

  public emitManTranscript = async (transcript: string) => {
    if (!this.manualClient || !this.manualClient.active || !transcript) return;
    this.manualClient.emit("manTranscript", transcript);
  };

  public resetRealtimeSession = async () => {
    this.transcript = {
      ranges: [],
      pending: "",
      unstable: "",
    };

    this.host = io(``);
    this.client = io(``);

    this.client.removeAllListeners();
  };

  public record = async () => {
    const mediaStream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: false
      },
    });
    this.MediaStream = mediaStream;
    this.RecordRTC = new RecordRTC(mediaStream, {
      type: "audio",
      mimeType: "audio/wav",
      recorderType: StereoAudioRecorder,
      disableLogs: true,
      ondataavailable: async (blob: any) => {
        const ab = await blob.arrayBuffer();
        const stereo = new Int16Array(ab);
        const offset = 44;
        const mono = new Int16Array((stereo.length / 2) - offset);
        for (let i = offset, j = 0; i < stereo.length; i += 4) {
          mono[j++] = stereo[i];
          mono[j++] = stereo[i + 1];
        }
        this.host.emit("data", mono);
      },
      checkForInactiveTracks: false,
      timeSlice: 100,
      bitsPerSecond: this.sampleRate * 16 * 2,
      audioBitsPerSecond: this.sampleRate * 16 * 2,
      sampleRate: this.sampleRate,
      desiredSampRate: this.sampleRate,
      bufferSize: 4096,
      numberOfAudioChannels: 1,
    });

    this.RecordRTC.startRecording();
  };

  public pause = async () => {
    this.RecordRTC.stopRecording(() => {
      //@ts-ignore
      this.MediaStream.stop()
      console.log("Recording Paused");
    });
  };

  public stop = async () => {
    if (!this.RecordRTC) return;
    this.RecordRTC.stopRecording(() => {
      this.host.close();
      this.client.close();
      //@ts-ignore
      this.MediaStream.stop()
      console.log("Recording Stoppped");
    });
  };

  public get trans() {
    if (!this.transcript) return {};
    return this.transcript;
  }

  public async createLiveSession(sessionDetails: {
    userId: string;
    name: string;
    lang: string;
    speaker: string;
  }) {
    const createLiveSessionEndpoint = config.cloudFunctions.baseUrl + config.cloudFunctions.createLiveSession;
    const liveSession = await axios.post(createLiveSessionEndpoint, sessionDetails)

    return liveSession.data;
  }

  public async endLiveSession(jobId: string) {
    await this.stop()

    const closeEndpoint = config.cloudFunctions.baseUrl + config.cloudFunctions.endLiveSession;

    await axios.post(closeEndpoint, { jobId, userId: "accessibility2" })
  }

  public async stereoToMonoChunks(blob: Blob) {
    // this.queue.push(blob)

    // if (this.queue.length < 3) {
    //   return;
    // }

    // const ab = await blob.arrayBuffer();
    // const intArrs = await Promise.all(_.map(this.queue, async (bl) => {
    //   const buffer = await bl.arrayBuffer();
    //   const tt = new Int16Array(buffer)
    //   console.log(tt.length);

    //   return tt;
    // }))
    // const newIntArr = new Int16Array(intArrs[0].length * 3);
    // newIntArr.set(intArrs[0], 0)
    // newIntArr.set(intArrs[1], intArrs[0].length)
    // newIntArr.set(intArrs[2], intArrs[0].length + intArrs[1].length)

    // const ab = await new Blob(this.queue).arrayBuffer()
    // const stereo = new Int16Array(ab);
    // const mono = new Int16Array(stereo.length / 2);
    // for (let i = 0, j = 0; i < stereo.length; i += 4) {
    //   mono[j++] = stereo[i];
    //   mono[j++] = stereo[i + 1];
    // }
  }

  public static getInstance = () => {
    if (!Realtime.instance) Realtime.instance = new Realtime();
    return Realtime.instance;
  };
}

export default Realtime.getInstance();
