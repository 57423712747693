import React, { FC } from 'react'
import { format } from 'date-fns';
import { LiveJob } from '../../../types/Live';

import "./LiveSessionCard.scss";
import { useTranslation } from 'react-i18next';

interface Props {
  job: LiveJob,
  onClick: () => void
}

export const LiveSessionCard: FC<Props> = ({
  job,
  onClick
}) => {
  const { t } = useTranslation();

  return (
    <div className="LiveSessionCardComponent" onClick={onClick}>
      <div className="name">{job.name}</div>
      <div className="speaker">{job.speaker}<span className="lang">({t(job.lang)})</span></div>
      <div className="creationTime">{format(new Date(job.creationTime), "HH:mm")}</div>

      {job.status === "live" && <div className="liveBadgeContainer">
        <span className="liveTitle">{t("live_badge")}</span>
        <span className="liveLength">{job.jobLength}</span>
      </div>}
    </div>
  )
}


export default LiveSessionCard