import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { auth } from '../../services/FirebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';

import { LiveJob } from '../../types/Live';

import { getUserJobs } from '../../services/JobService';
import RealtimeService from '../../services/RealtimeService';

import Button from "../../components/common/Button/Button";
import LiveSessionCard from '../../components/common/LiveSessionCard/LiveSessionCard';
import StartNewLiveModal from '../../components/StartNewLiveModal/StartNewLiveModal';

import "./Lobby.scss";

export const Lobby = () => {
  const [user, loading, error] = useAuthState(auth);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [jobs, setJobs] = useState<LiveJob[]>([]);

  const [showStartModal, setShowStartModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserJobs("accessibility2", { status: "live" }).then(_jobs => setJobs(_jobs));
  }, [])

  const handleCreateLiveSession = async (
    { name, speaker, lang }: 
    { name: string, speaker: string, lang: string }) => {
    const newSession = await RealtimeService.createLiveSession({
      userId: "accessibility2",
      name,
      lang,
      speaker
    });
    navigate(`/live/${newSession.jobId}`)
  }

  const goToSession = (jobId: string) => {
    navigate(`/live/${jobId}`)
  }

  return !user ? <Navigate to="/login" /> : (
    <div className="LobbyPage pageContainer">
      <Button onClick={() => setShowStartModal(true)}>{t("lobby_start_new_live")}</Button>
      <div className="liveSessions">
        <div className="liveSessionsTitle">{t("lobby_live_sessions_title")}</div>
        <div className="liveSessionsContainer">
          {_.map(jobs, job => (
            <LiveSessionCard
              job={job}
              onClick={() => goToSession(job.jobId)}
              key={job.jobId}
            />
          ))}
        </div>
      </div>
      <StartNewLiveModal
        showModal={showStartModal}
        approve={{
          text: t("start"),
          action: handleCreateLiveSession
        }}
        cancel={{
          text: t("cancel"),
          action: () => setShowStartModal(false)
        }}
      />
    </div>
  )
}
