import React, { FC } from 'react';
import MUIButton from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import classNames from 'classnames';

import "./Button.scss";

interface Props {
  onClick: () => void;
  children: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: "contained" | "text" | "outlined";
}

const Button: FC<Props> = ({
  onClick,
  children,
  disabled,
  loading,
  className,
  variant = "contained",
}) => {
  return (
    <LoadingButton
      variant={variant}
      className={classNames("ButtonComponent", className)}
      onClick={onClick}
      disabled={disabled || loading}
      loading={loading}
    >
      {children}
    </LoadingButton>)
}

export default Button;