import React, { useState } from 'react'
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames';
import { Navigate } from 'react-router-dom';
import { auth } from '../../services/FirebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';

import RealtimeService from '../../services/RealtimeService';
import { getJob } from '../../services/JobService';

import Button from '../../components/common/Button/Button';
import Input from '../../components/common/Input/Input'

import { getLanguageDirection } from '../../utils/locales';

import "./ManualTranscriptor.scss";

export const ManualTranscriptor = () => {
  const [user, loading, error] = useAuthState(auth);
  const { t } = useTranslation();
  const [jobId, setJobId] = useState("");
  const [jobName, setJobName] = useState<string>();
  const [jobDirection, setJobDirection] = useState<"ltr" | "rtl">("rtl");
  const [connectionStatus, setConnectionStatus] = useState("disconnected");

  const [transcript, setTranscript] = useState("");
  const [transcriptInput, setTranscriptInput] = useState("");

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.code === "Enter") {
      emitTranscriptInput()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === "Enter" || e.code === "Space") {
      emitLastWord();
    }
  }

  const emitTranscriptInput = async () => {
    RealtimeService.emitManTranscript(transcriptInput)
    setTranscript([transcript, transcriptInput].join(" "));
    setTranscriptInput("");
  }

  const emitLastWord = async (updatedTranscript = transcriptInput) => {
    const transArr = updatedTranscript.split(" ").filter(w => w);;
    const lastWord = _.last(transArr);

    if (!lastWord) return;

    RealtimeService.emitManTranscript(lastWord)
  }

  const handleConnect = async () => {
    setConnectionStatus("connecting");
    RealtimeService.initManualTranscriptor({ jobId, onConnect: handleConnected });
  }

  const handleConnected = async () => {
    const job = await getJob(jobId);
    setJobName(job.name);
    setJobDirection(getLanguageDirection(job.lang))
    setConnectionStatus("connected")
  }

  return !user ? <Navigate to="/login" /> : 
   (
    <div className="ManualTranscriptor">
      <div className="connectionContainer">
        <Input
          value={jobId}
          onChange={(e) => setJobId(e.target.value)}
          placeholder={t("manual_transcriptor_jobid")}
          disabled={connectionStatus !== "disconnected"}
        />
        <Button
          className="connectBtn"
          disabled={connectionStatus !== "disconnected"}
          onClick={handleConnect}
          loading={connectionStatus === "connecting"}
        >{t("manual_transcriptor_connect")}
        </Button>
      </div>

      <div className={classNames("connectionStatusContainer", connectionStatus)}>
        <div className="connectionIndicator">
          <FontAwesomeIcon icon={faCircleDot} className="indicator" />
        </div>
        <div className="connectionStatus">
          {t(`socket_status_${connectionStatus}`)}
        </div>
        {connectionStatus === "connected" && (
          <div className="jobName">[{jobName}]</div>
        )}
      </div>

      <div className="transcriptContainer">
        <div className={classNames("transcript", jobDirection)}>
          <textarea
            className='transcriptInput'
            value={transcriptInput}
            onKeyDown={handleKeyDown}
            onChange={(e) => setTranscriptInput(e.target.value)}
          />
        </div>
        {connectionStatus !== "connected" && (
          <div className="disabled" />
        )}
      </div>

    </div>
  )
}
