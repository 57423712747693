module.exports = {
  environment: "development",
  isDev: true,
  envUrl: "http://rt-dev.sumit-ai.com/",
  idleTime: 30 * 1000,
  realtime: {
    sessionManager: "https://session-manager.sumit-labs.com/",
    create: "create",
    close: "close"
  },
  cloudFunctions: {
    baseUrl: "https://us-central1-sumit-dev-0.cloudfunctions.net/",
    createLiveSession: "realtime-createLiveSession",
    endLiveSession: "realtime-endLiveSession",
    jobs: {
      getUserJobs: "realtime-jobs-getUserJobs",
      getJob: "realtime-jobs-getJob",
      saveJob: "realtime-jobs-saveJob"
    }
  }
};
