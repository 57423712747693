import React, { FC, ReactEventHandler, useEffect, useState } from "react";
import _ from "lodash";
import classNames from "classnames";

import "./Input.scss";

interface Props {
  value: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholder?: string | number;
  error?: string | null;
  required?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  type?: "text" | "number" | "password" | "email";
}

const Input: FC<Props> = ({
  value,
  onChange,
  className,
  placeholder,
  error,
  autofocus,
  required = false,
  disabled = false,
  min,
  max,
  type = "text",
}): JSX.Element => {
  const [newValue, setnewValue] = useState(value);

  return (
    <div className={classNames("Input", className, { error: !!error })}>
      <input
        placeholder={placeholder?.toString()}
        autoFocus={autofocus}
        required={required}
        disabled={disabled}
        type={type}
        value={newValue}
        onChange={(e) => {
          if (min && Number(e.target.value) < min) {
            return min;
          } else if (max && Number(e.target.value) > max) {
            return;
          }
          setnewValue(e.target.value);
          onChange(e);
        }}
        min={min}
        max={max}
      />
      {error && <div className="inputError">{error}</div>}
    </div>
  );
};

export default Input;
