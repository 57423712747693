import React, { useState } from 'react'
import { useAuthState, useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useTranslation } from 'react-i18next';
import Button from '../../components/common/Button/Button';
import Input from '../../components/common/Input/Input';
import { auth } from '../../services/FirebaseService';

export const Login = () => {
  const { t } = useTranslation();
  const [user, loading, error] = useAuthState(auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [
    signInWithEmailAndPassword,
    useSignUser,
    useSignLoading,
    useSignError,
  ] = useSignInWithEmailAndPassword(auth);
  
  if (useSignError) {
    return (
      <div>
        <p>Error: {useSignError.message}</p>
      </div>
    );
  }
  if (useSignLoading) {
    return <p>Loading...</p>;
  }
  if (user) {
    return (
      <div>
        <p>Signed In User: {user.email}</p>
        <Button onClick={() => auth.signOut()}>{t("sign_out")}</Button>
      </div>
    );
  }
  return (
    <div className="App">
      <Input
        type="email"
        value={email}
        placeholder={t("email")}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        type="password"
        value={password}
        placeholder={t("password")}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button onClick={() => signInWithEmailAndPassword(email, password)}>
        {t("sign_in")}
      </Button>
    </div>
  );
}
