import React from 'react'
import { CircularProgress } from '@mui/material'
import classNames from 'classnames';

import "./Loader.scss";

export const Loader = ({
  transparent = true
}:{
  transparent?: boolean;
}) => {
  return (
    <div className={classNames("LoaderComponent", {transparent})}>
      <CircularProgress />
    </div>
  )
}
